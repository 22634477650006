/*
Font Epilot Icons full
*/
@include font-face(
  'Epilot-Icon',
  '../fonts/icon.nucleo/ep-icon-full',
  $file-formats: $ep-file-format
);
@import 'iconfonts/_ep-icon';

@include font-face(
  'Epilot-Icon-Custom-v2',
  '../fonts/icon.custom.v2/ep-iconset-custom',
  $file-formats: $ep-file-format
);
@import 'iconfonts/_epc-icon';

// --------- Montserrat ---------------

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-Thin',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 100;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-ThinItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 100;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-ExtraLight',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 200;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-ExtraLightItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 200;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-Light',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 300;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-LightItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 300;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-Regular',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 400;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-Italic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 400;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-Medium',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 500;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-MediumItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 500;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-SemiBold',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 600;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-SemiBoldItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 600;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-Bold',
  $file-formats: ttf
) {
  font-style: normal;
  font-weight: 700;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-BoldItalic',
  $file-formats: ttf
) {
  font-style: italic;
  font-weight: 700;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-ExtraBold',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 800;
};

@include font-face(
  'Montserrat',
  '../fonts/montserrat/Montserrat-ExtraBoldItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 800;
};

// --------- Roboto ---------------

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Thin',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 100;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-ThinItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 100;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Light',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 300;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-LightItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 300;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Regular',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 400;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Italic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 400;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Medium',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 500;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-MediumItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 500;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Bold',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 700;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-BoldItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 700;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-Black',
  $file-formats: ttf,
) {
  font-style: normal;
  font-weight: 900;
};

@include font-face(
  'Roboto',
  '../fonts/roboto/Roboto-BlackItalic',
  $file-formats: ttf,
) {
  font-style: italic;
  font-weight: 900;
};
